export class Urls {

  public production: boolean = true;

  public urlBase() {
    if (this.production) {
      ///  return 'https://inventarioronny.herokuapp.com';
      /// return 'http://45.58.46.214';
      return 'https://databacken.com';
      //return 'http://localhost:8500';
    } else {
      return 'http://localhost:3000';
    }
  }

  public authentication: any = {
    login: this.urlBase().concat('/Authentication/LogIn'),
    myProfile: this.urlBase().concat('/Api/MyProfile'),
    login2: this.urlBase().concat('/rest/api/user/login')


  };

  public affiliate: any = {
    affiliateSingUp: this.urlBase().concat('/Api/Affiliate/AffiliateSingUp'),
    affiliateList: this.urlBase().concat('/Api/Affiliate/AffiliateList?'),
    affiliatesListCount: this.urlBase().concat('/Api/Affiliate/affiliatesListCount?'),
    affiliatedByIdManager: this.urlBase().concat('/Api/Affiliate/affiliatedByIdManager?affiliated_id='),
    deleteAffiliated: this.urlBase().concat('/Api/Affiliate/deleteAffiliated?affiliated_id=')
  };

  public customer: any = {
    customerList: this.urlBase().concat('/Api/Customer/list?'),
    customerCount: this.urlBase().concat('/Api/Customer/customerCount?'),
    customerNameID: this.urlBase().concat('/Api/Customer/customerNameID'),
    registerCostumer: this.urlBase().concat('/Api/Customer/register'),
    customerByManager: this.urlBase().concat('/Api/Customer/customerByManager?customer='),
    customerByAffiliated: this.urlBase().concat('/Api/Customer/customerByAffiliated?customer='),
    deleteCustomer: this.urlBase().concat('/Api/Customer/deleteCustomer?customer_id=')
  };


  public us: any = {
    states: this.urlBase().concat('/states'),
    city: this.urlBase().concat('/city?state='),
    edit_city: this.urlBase().concat('/edit_city')
  };


  public Order: any = {
    createOrder: this.urlBase().concat('/Api/Order/createOrder'),
    listOrder: this.urlBase().concat('/Api/Order/listOrder?'),
    getOrdersCount: this.urlBase().concat('/Api/Order/getOrdersCount?'),

    orderListByAffiliated: this.urlBase().concat('/Api/Order/orderListByAffiliated'),
    orderToTransit: this.urlBase().concat('/Api/Order/orderToTransit'),
    cancelOrder: this.urlBase().concat('/Api/Order/cancelOrder?order_id='),
    deleteOrder: this.urlBase().concat('/Api/Order/deleteOrder?order_id='),
    deliveredOrder: this.urlBase().concat('/Api/Order/deliveredOrder?order_id='),
    orderByIdForManager: this.urlBase().concat('/Api/Order/orderByIdForManager?order_id='),
    orderToStatusTransit: this.urlBase().concat('/Api/Order/orderToStatusTransit?order_id='),
    orderToStatusPickUp: this.urlBase().concat('/Api/Order/orderToStatusPickUp?order_id='),
    orderOnHold: this.urlBase().concat('/Api/Order/orderOnHold?order_id='),
    orderPending: this.urlBase().concat('/Api/Order/orderPending?order_id='),
    orderReturned: this.urlBase().concat('/Api/Order/orderReturned?order_id='),
    payOrders: this.urlBase().concat('/Api/Order/payOrder'),
    updateOrder: this.urlBase().concat('/Api/Order/updateOrder'),
    ordersPay: this.urlBase().concat('/Api/Order/ordersPay?'),
    ordersPayDetail: this.urlBase().concat('/Api/Order/ordersPayDetail?'),
    ordersPayDetailCunt: this.urlBase().concat('/Api/Order/ordersPayDetailCunt?'),
    comissionOrdersPendingToPay: this.urlBase().concat('/Api/Order/comissionOrdersPendingToPay?affiliated_id='),
    comissionOrdersPayed: this.urlBase().concat('/Api/Order/comissionOrdersPayed?affiliated_id='),
    updateOrderNoFormula: this.urlBase().concat('/Api/Order/updateOrderNoFormula'),
    reportsOrders: this.urlBase().concat('/Api/Order/reportsOrders'),
    reportsOrdersCount: this.urlBase().concat('/Api/Order/reportsOrdersCount'),
    refreshOrdersStatus: this.urlBase().concat('/Api/Order/refreshOrdersStatus'),

    comissionOrdersPendingToPayBetween: this.urlBase().concat('/Api/Order/comissionOrdersPendingToPayBetween')
  };


  public permission: any = {
    permissionsByUserId: this.urlBase().concat('/Api/Permission/PermissionsByUserId?userId='),
    addPermission: this.urlBase().concat('/Api/Permission/addPermission'),
    seePermissions: this.urlBase().concat('/Api/Permission/seePermissions'),
    checkPermission: this.urlBase().concat('/Api/Permission/checkPermission/'),
    getPermissionByUserId: this.urlBase().concat('/Api/Permission/getPermissionByUserId/')
  };

  public products: any = {
    register: this.urlBase().concat('/Api/Product/register'),
    userList: this.urlBase().concat('/Api/Product/list?'),
    productNameID: this.urlBase().concat('/Api/Product/productNameID'),
    productCount: this.urlBase().concat('/Api/Product/productCount?'),
    productById: this.urlBase().concat('/Api/Product/productById?')

  };

  public warehouse: any = {
    register: this.urlBase().concat('/Api/Warehouse/register'),
    update: this.urlBase().concat('/Api/Warehouse/update'),
    list: this.urlBase().concat('/Api/Warehouse/list?'),
    list_count: this.urlBase().concat('/Api/Warehouse/list_count?'),
    by_id: this.urlBase().concat('/Api/Warehouse/by_id?warehouse_id='),
    product_by_warehouse_id: this.urlBase().concat('/Api/Warehouse/product_by_warehouse_id?warehouse_id='),
    insert_warehouse_product: this.urlBase().concat('/Api/Warehouse/insert_warehouse_product'),
    quantityProductByWarehouse: this.urlBase().concat('/Api/Warehouse/quantityProductByWarehouse?warehouse_id=')
  };


//////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor() {

  }

}
