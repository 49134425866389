import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    encapsulation: ViewEncapsulation.None,
    templateUrl: './validation-error.component.html',
    styleUrls: ['./validation-error.component.scss']
})
export class ValidationError implements OnInit {


    @Input() Errors: any;
    // @Input() Detail: string = "";
    // @Input() Code: string = "";

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {

    }

}
