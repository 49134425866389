import {Injectable} from '@angular/core';

@Injectable()
export class DeviceDetectorService {

    constructor() {
    }

    public isDesktop(): boolean {
        return true;
    }
}
