import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-order-modal',
  templateUrl: './order-modal.component.html',
  styleUrls: ['./order-modal.component.css'],

})
export class OrderModalComponent implements OnInit {

  @Input() fedex_status: any;
  @Input() callback: any;
  @Input() tracking_name: string = "";
  @Input() currentStatus: number = 0;
  private status_to_set: any = 0;


  constructor(public activeModal: NgbActiveModal,) {
  }

  ngOnInit() {
  }


  public changeStatus(value) {
    this.status_to_set = value;
  }


  public applyStatus() {
    if (this.status_to_set == 0) {
      if (this.fedex_status.isInTransit == true) {
        this.status_to_set = 4;
      } else if (this.fedex_status.isPending == true) {
        this.status_to_set = 2;
      } else if (this.fedex_status.isPickup == true) {
        this.status_to_set = 3;
      } else if (this.fedex_status.isDelivered == true) {
        this.status_to_set = 5;
      } else if (this.fedex_status.isException == true) {
        this.status_to_set = 8;
      }


      ///Falta definir Hold estado 7
    }

    this.callback({
      newStatus: this.status_to_set,
      tracking: this.fedex_status.trackingNbr
    });
  }


}
