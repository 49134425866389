import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UrlNotFoundComponent} from './url-not-found/url-not-found.component';
import {DatabaseErrorComponent} from "./database-error/database-error.component";
import {Router} from "@angular/router";
import {UserBlockedComponent} from "./user-blocked/user-blocked.component";
import {ServeErrorComponent} from "./serve-error/serve-error.component";
import {NotConnectionComponent} from "./not-connection/not-connection.component";
import {ValidationError} from "./validation-error/validation-error.component";
import {HttpErrorResponse} from '@angular/common/http';


@Injectable()
export class MyErrorHttpServices {

    private myStatus = {
        databaseError: 600,
        serveError: 450,
        validationError: 701,
        Unauthorized: 401,
        userBlock: 345,
        UrlNotFound: 404,
        LoginFaceBookFail: 432,
        NotConnection: 0,
        NotPermission: 451

    };

    constructor(private modalService: NgbModal, private router: Router) {
    }


    public inspectErrorHttp(error: HttpErrorResponse) {

        switch (error.status) {
            case this.myStatus.databaseError:
                this.databaseError(error);
                break;
            case this.myStatus.serveError:
                this.serveError(error);
                break;
            case  this.myStatus.NotPermission :
                this.serveError(error);
                ///this.router.navigate(['/login']);
                window.history.back();
                break;
            case this.myStatus.validationError:
                this.validationError(error);
                break;
            case this.myStatus.Unauthorized:
                this.urlNoFund(error);
                break;
            case this.myStatus.userBlock:

                this.userBlocked(error);
                break;
            case this.myStatus.UrlNotFound:
                this.urlNoFund(error);
                break;
            case this.myStatus.LoginFaceBookFail:
                this.urlNoFund(error);
                break;
            case this.myStatus.NotConnection:
                this.notConnection(error);
                break;
            default:
                console.log(error);
                break;
        }

    }

    private urlNoFund(error: any) {
        ///// console.log(error.error.error);
        const modalRef = this.modalService.open(UrlNotFoundComponent, {windowClass: 'dark-modal', size: 'sm'});
        modalRef.componentInstance.Name = error.error.name;
        modalRef.componentInstance.Detail = error.error.details;
        modalRef.componentInstance.Message = error.error.message;
        modalRef.componentInstance.Code = error.error.code;
    }

    public databaseError(error: any) {

        const modalRef = this.modalService.open(DatabaseErrorComponent, {windowClass: 'amarillo'});
///        modalRef.componentInstance.error = error.error;
        console.log(error.error)
        modalRef.componentInstance.Name = error.error.name;
        modalRef.componentInstance.Errors = error.error.original.sqlMessage;
        /// modalRef.componentInstance.Code = error.original.error.errno;
    }

    private userBlocked(error: any) {
        this.router.navigate(['/login']);
        const modalRef = this.modalService.open(UserBlockedComponent, {windowClass: 'red', size: 'sm'});
        modalRef.componentInstance.Name = error.error.name;
        modalRef.componentInstance.Detail = error.error.details;
        modalRef.componentInstance.Message = error.error.message;
        modalRef.componentInstance.Code = error.error.code;
    }


    private serveError(error: any) {
        const modalRef = this.modalService.open(ServeErrorComponent, {windowClass: 'dark-modal', size: 'sm'});
        modalRef.componentInstance.Name = error.error.name;
        modalRef.componentInstance.Detail = error.error.details;
        modalRef.componentInstance.Message = error.error.message;
        modalRef.componentInstance.Code = error.error.code;

    }

    private validationError(error: any) {
        const modalRef = this.modalService.open(ValidationError, {windowClass: 'validation', size: 'sm'});
        modalRef.componentInstance.Errors = error.error;
    }

    private notConnection(error: any) {
        const modalRef = this.modalService.open(NotConnectionComponent, {windowClass: 'verde', size: 'sm'});
    }


}
