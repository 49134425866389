export class TypesUsers {

    public SYSTEM_MANAGER = 1;

    public AFFILIATED = 2;

    constructor() {
    }


}