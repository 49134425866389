import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";

const routes: Routes = [

    {path: 'affiliated', loadChildren: './affiliated/affiliated.module#AffiliatedModule'},
    {path: 'manager', loadChildren: './manager/manager.module#ManagerModule'},
    {path: 'login', loadChildren: './login/login.module#LoginModule'},
    {path: 'not-found', loadChildren: './not-fund/not-fund.module#NotFundModule'},
    {path: '**', redirectTo: 'not-found'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
