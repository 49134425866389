import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from "rxjs/index";
import {MyErrorHttpServices} from "../my-rrors-http/my-error-http.service";

@Injectable()
export class HttpRequestService {


    constructor(private httpClient: HttpClient, public myErrorHttpServices: MyErrorHttpServices) {

    }

    public post(myHeaders, url: string, body: any): Observable<any> {
        let headers: any, bearer: string = '';
        const token = JSON.parse(sessionStorage.getItem('token'));
        if (token) {
            bearer = 'Bearer '.concat(token.token);
        }


        if (myHeaders) {
            if (!myHeaders.Authorization) {
                myHeaders.Authorization = bearer;
            }
            headers = new HttpHeaders(myHeaders);
        } else {
            headers = new HttpHeaders({Authorization: bearer});
        }
        headers = headers.append('Accept-Language', (sessionStorage.getItem('language')) ? sessionStorage.getItem('language') : 'en');
        return Observable.create(observer => {
            this.httpClient.post(url, body, {headers: headers})
                .subscribe(
                    data => observer.next(data),
                    error => {
                        this.myErrorHttpServices.inspectErrorHttp(error);
                        observer.error(error);
                    },
                    () => {
                        observer.complete();
                    });
        });
    }


    public get(myHeaders, url: string): Observable<any> {
        let headers: any, bearer: string = '';
        const token = JSON.parse(sessionStorage.getItem('token'));
        if (token) {
            bearer = 'Bearer '.concat(token.token);
        }

        if (myHeaders) {
            if (!myHeaders.Authorization) {
                myHeaders.Authorization = bearer;
            }
            headers = new HttpHeaders(myHeaders);
        } else {
            headers = new HttpHeaders({Authorization: bearer});
        }
        headers = headers.append('Accept-Language', (sessionStorage.getItem('language')) ? sessionStorage.getItem('language') : 'en');

        return Observable.create(observer => {
            this.httpClient.get(url, {headers: headers})
                .subscribe(
                    data => observer.next(data),
                    error => {
                        this.myErrorHttpServices.inspectErrorHttp(error);
                        observer.error(error);
                    },
                    () => {
                        observer.complete();
                    });
        });
    }


    public put(myHeaders, url: string, body: any): Observable<any> {
        let headers: any, bearer: string = '';
        const token = JSON.parse(sessionStorage.getItem('token'));
        if (token) {
            bearer = 'Bearer '.concat(token.token);
        }

        if (myHeaders) {
            if (!myHeaders.Authorization) {
                myHeaders.Authorization = bearer;
            }
            headers = new HttpHeaders(myHeaders);
        } else {
            headers = new HttpHeaders({Authorization: bearer});
        }
        headers = headers.append('Accept-Language', (sessionStorage.getItem('language')) ? sessionStorage.getItem('language') : 'en');

        return Observable.create(observer => {
            this.httpClient.put(url, body, {headers: headers})
                .subscribe(
                    data => observer.next(data),
                    error => {
                        this.myErrorHttpServices.inspectErrorHttp(error);
                        observer.error(error);
                    },
                    () => {
                        observer.complete();
                    });
        });


    }


    public fedexPost(numberTracking: any): Observable<any> {
        let headers: any = new HttpHeaders(),
            body = new FormData();
        body.append('data', `{"TrackPackagesRequest":{"appType":"WTRK","appDeviceType":"","supportHTML":true,"supportCurrentLocation":true,"uniqueKey":"","processingParameters":{},"trackingInfoList":[{"trackNumberInfo":{"trackingNumber":"775334379201","trackingQualifier":"","trackingCarrier":""}}]}}`.trim());
        body.append('action', 'trackpackages');
        body.append('format', 'json');
        body.append('version', '1');


        headers = headers.append('content-type', 'application/x-www-form-urlencoded');
        return Observable.create(observer => {
            this.httpClient.post('https://www.fedex.com/trackingCal/track', body, {headers: headers})
                .subscribe(
                    data => observer.next(data),
                    error => {
                        this.myErrorHttpServices.inspectErrorHttp(error);
                        observer.error(error);
                    },
                    () => {
                        observer.complete();
                    });
        });
    }


}



