import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {AppComponent} from './app.component';
import {AppRoutingModule} from "./app-routing.module";
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {UserBlockedComponent} from "./http/my-rrors-http/user-blocked/user-blocked.component";
import {ServeErrorComponent} from "./http/my-rrors-http/serve-error/serve-error.component";
import {NotConnectionComponent} from "./http/my-rrors-http/not-connection/not-connection.component";
import {Urls} from "./config/urls";
import {MyErrorHttpServices} from "./http/my-rrors-http/my-error-http.service";
import {HttpRequestService} from "./http/request/request.service";
import {NgProgressModule} from "@ngx-progressbar/core";
import {NgProgressRouterModule} from "@ngx-progressbar/router";
import {NgProgressHttpModule} from "@ngx-progressbar/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TypesUsers} from "./config/usersTypes";
import {PermissionService} from "./services/permissions/permission.service";
import {ValidationError} from "./http/my-rrors-http/validation-error/validation-error.component";
import {DatabaseErrorComponent} from "./http/my-rrors-http/database-error/database-error.component";
import {UrlNotFoundComponent} from "./http/my-rrors-http/url-not-found/url-not-found.component";
import {OrderService} from "../order-service/order-service";
import {DeviceDetectorService} from "./services/devices/device-detector-service.service";
import {ConnectionService} from "ng-connection-service";
import {XlsService} from "./services/devices/xlsx.service";
import {OrderModalComponent} from "../order-service/order-modal/order-modal.component";

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
    // for development
    // return new TranslateHttpLoader(http, '/start-angular/SB-Admin-BS4-Angular-5/master/dist/assets/i18n/', '.json');
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({

    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgbModule.forRoot(),
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        NgProgressModule,
        NgProgressRouterModule,
        NgProgressHttpModule,


    ],
    declarations: [
        AppComponent,
        UrlNotFoundComponent,
        DatabaseErrorComponent,
        ValidationError,
        UserBlockedComponent,
        ServeErrorComponent,
        NotConnectionComponent,
        OrderModalComponent
    ],
    entryComponents: [
        UrlNotFoundComponent,
        DatabaseErrorComponent,
        ValidationError,
        UserBlockedComponent,
        ServeErrorComponent,
        NotConnectionComponent,
        OrderModalComponent
    ],
    providers: [
        Urls,
        MyErrorHttpServices,
        HttpRequestService,
        TypesUsers,
        PermissionService,
        OrderService,
        DeviceDetectorService,
        ConnectionService,
        XlsService
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
