import {Injectable} from '@angular/core';
import {HttpRequestService} from "../../http/request/request.service";
import {Urls} from "../../config/urls";

@Injectable()
export class PermissionService {


    public permissions: any = {
        add_affiliate: false,
        affiliate_list: false,
        product_edit: false,
        add_product: false,
        product_list: false,
        add_customer: false,
        customer_list: false,
        customer_edit: false
    };


    constructor(private httpRequestService: HttpRequestService,
                private url: Urls) {

        this.getPermissionByUserId();
    }


    public checkPermissions(permission_id, callback) {
        this.httpRequestService.get(null, this.url.permission.checkPermission.concat(permission_id))
            .subscribe(permissions => callback(permissions),
                error2 => console.log(error2),
                () => console.log('request completed'));
    }

    public getPermissionByUserId() {
        return this.httpRequestService.get(null, this.url.permission.getPermissionByUserId)
            .subscribe(data => this.permissions = data,
                error => {
                },
                () => {
                });

    }


}
