import {Component, OnInit, Input, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    encapsulation: ViewEncapsulation.None,
    templateUrl: './url-not-found.component.html',
    styleUrls: ['./url-not-found.component.scss'],

})
export class UrlNotFoundComponent implements OnInit {

    @Input() Name: string = "";
    @Input() Message: string = "";
    @Input() Detail: string = "";
    @Input() Code: string = "";


    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
    }

}
