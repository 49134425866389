import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    encapsulation: ViewEncapsulation.None,
    templateUrl: './not-connection.component.html',
    styleUrls: ['./not-connection.component.scss']
})
export class NotConnectionComponent implements OnInit {
    @Input() Name: string = "";
    @Input() Message: string = "";
    @Input() Detail: string = "";
    @Input() Code: string = "";
    private interval: any;

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.contador();
    }


    public contador() {
        this.interval = setInterval(() => {
            if (document.getElementById('contadornotconection')) {
            } else {
                clearInterval(this.interval);
                this.retryNetwork();
            }
        }, 1000);
    }

    public retryNetwork() {
        location.reload();
    }
}
