import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    encapsulation: ViewEncapsulation.None,
    templateUrl: './user-blocked.component.html',
    styleUrls: ['./user-blocked.component.scss']
})
export class UserBlockedComponent implements OnInit {


    @Input() Name: string = "";
    @Input() Message: string = "";
    @Input() Detail: string = "";
    @Input() Code: string = "";


    constructor(public activeModal: NgbActiveModal) {
    }


    ngOnInit() {
    }

}
