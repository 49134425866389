import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    encapsulation: ViewEncapsulation.None,
    templateUrl: './database-error.component.html',
    styleUrls: ['./database-error.component.scss']
})
export class DatabaseErrorComponent implements OnInit {


    @Input() Name: string = "";
    @Input() Errors: string = "";
    // @Input() Detail: string = "";
   ///  @Input() Code: string = "";

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
    }

}
