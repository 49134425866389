import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    encapsulation: ViewEncapsulation.None,
    templateUrl: './serve-error.component.html',
    styleUrls: ['./serve-error.component.scss']
})
export class ServeErrorComponent implements OnInit {

    @Input() Name: string = "";
    @Input() Message: string = "";
    @Input() Detail: string = "";
    @Input() Code: string = "";

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
    }

}
