import {Injectable} from '@angular/core';
import {Urls} from "../app/config/urls";
import {HttpRequestService} from "../app/http/request/request.service";

@Injectable()
export class OrderService {


    constructor(private httpRequestService: HttpRequestService,
                private url: Urls) {

    }


    public checkStatus(status: any, showPayed: boolean = false) {
        if (status == 0) {
            return 'Cancel';
        } else if (status == 1 || status == 2) {
            return 'Pending';
        } else if (status == 3) {
            return 'Pick up';
        } else if (status == 4) {
            return 'In Transit';
        } else if (status == 5) {
            return 'Delivered';
        } else if (status == 6 && showPayed == false) {
            return 'Delivered';
        } else if (status == 6 && showPayed == true) {
            return 'Payed';
        } else if (status == 7) {
            return 'Hold';
        } else if (status == 8) {
            return 'Returned';
        } else {
            return '';
        }
    }


    public refreshStatusOrder(tracking: any) {
        // return new Promise((resolve, reject) => {
        //     this.httpRequestService.post(
        //         null,
        //         this.url.Order.refreshOrdersStatus,
        //         {
        //             "tracking": (order.order_type_id == 1) ? order.order_tracking2 : order.order_tracking
        //         }
        //     ).subscribe((data: any) => {
        //         const modalRef = this.modalService.open(OrderModalComponent, {windowClass: 'dark-modal', size: 'lg'});
        //         modalRef.componentInstance.fedex_status = data;
        //         modalRef.componentInstance.callback = callback_to_refresh_orders;
        //
        //     }, (err) => reject(err));
        // });

      ////  console.log(order)
      //
      //   let tracking: any;
      //   if (order.order_type.order_type_id == 1 && order.order_status > 2) {
      //       tracking = order.order_tracking2;
      //   } else {
      //       tracking = order.order_tracking;
      //
      //   }
        return new Promise((resolve, reject) => {
            this.httpRequestService.post(
                null,
                this.url.Order.refreshOrdersStatus,
                {
                    "tracking": tracking
                }
            ).subscribe((data: any) => resolve(data), (err) => reject(err));
        });


    }

}