import {Component, HostListener} from "@angular/core";
import {Router} from "@angular/router";
import {TypesUsers} from "./config/usersTypes";
import {TranslateService} from "@ngx-translate/core";
import {ConnectionService} from "ng-connection-service";

declare var $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {

    public isConnected = true;
    public timeToTry: number = 20;
    private lasttimeToTry = 30;
    private interval: any;


    public innerWidth: any;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.innerWidth = window.innerWidth;
        this.showScroll();
    }


    ngOnInit() {

        $('#scroll-1').scroll(function () {
            $('#content-print').scrollLeft($(this).scrollLeft());
        });

        $('#content-print').scroll(function () {
            $('#scroll-1').scrollLeft($(this).scrollLeft());
        });
    }

    private showScroll() {


        let width = $('.table').width();
        $('#f-scroll').css('width', width);

        let widthScroll = $('#content-print').width();
        $('#scroll-1').css('width', widthScroll);
    }


    constructor(private router: Router,
                private typesUsers: TypesUsers,
                private translate: TranslateService,
                private connectionService: ConnectionService) {

        this.connectionService.monitor().subscribe(isConnected => {
            this.isConnected = isConnected;
            this.contador();
        });

        translate.setDefaultLang('en');

        let routerConfig = this.router.config;
        const session = sessionStorage.getItem('token');
        if (session != undefined && session != null && session != "") {
            const userType = JSON.parse(session).userType;
            if (userType == this.typesUsers.SYSTEM_MANAGER) {
                //// this.variable = 1;
                routerConfig.unshift({
                    path: '',
                    loadChildren: './manager/manager.module#ManagerModule'
                });
                //// console.log('/app route (Portal) added', JSON.stringify(routerConfig));

                this.router.resetConfig(routerConfig);
            } else if (userType == this.typesUsers.AFFILIATED) {
                routerConfig.unshift({
                    path: '',
                    loadChildren: './affiliated/affiliated.module#AffiliatedModule'
                });

                /////console.log('/app route (Portal) added', JSON.stringify(routerConfig));

                this.router.resetConfig(routerConfig);

            } else {
                this.router.navigate(['/login']);
            }
        } else {
            this.router.navigate(['/login']);
        }
    }


    private contador() {
        if (this.isConnected == false) {
            this.interval = setInterval(() => {
                if (this.timeToTry == 0) {
                    this.timeToTry = this.lasttimeToTry;
                    this.lasttimeToTry += 10;
                } else {
                    this.timeToTry--;
                }
            }, 1000);
        } else {
            clearInterval(this.interval);
            location.reload();


        }

    }

    public retryNetwork() {
        this.timeToTry = 10;
        this.lasttimeToTry = 20;
        location.reload();

    }


}
